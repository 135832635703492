import React from 'react';
import { Heading, Divider, Stack } from '@chakra-ui/react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet';

import { DEFAULT_META_KEYWORDS, DEFAULT_META_DESCRIPTION, Wrapper, ContentWrapper } from './Home';
import { Lorem } from '../Components/Lorem/Lorem';

export default function Passage() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  var size = 'sm';
  if (isMobile) { size = 'xs'} else { size ='sm'}
  return (
      <>
      <Helmet>
        <title>Lipsum Generator - Lorem Ipsum Generator</title>
        <meta name="description" content={DEFAULT_META_DESCRIPTION} />
        <meta name="keywords" content={DEFAULT_META_KEYWORDS} />
      </Helmet>
      <ContentWrapper>
        <Wrapper>
        <Heading color="black" size='4xl'>Lorem Ipsum</Heading>
          <Stack direction='row' h='130px' p={4}>
            <Divider orientation='vertical' />
              <Heading as='h5' size={size} color="gray" style={{whiteSpace: "pre-line"}}>
              One must still have chaos in oneself to be able to give birth to a dancing star.
              {'\n\n'}- Friedrich Nietzsche
              </Heading>
          </Stack>
        </Wrapper>
        <Wrapper>
          <Lorem />
        </Wrapper>
      </ContentWrapper>
      </>
    );
}