import React from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Home from './Pages/Home';
import Passage from './Pages/Passage';
import Original from './Pages/Original';

export default class Main extends React.Component {
    render() {
        return (
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="*" element={<Home />} />
                <Route path="/generator" element={<Passage />} />
                <Route path="/original-passage" element={<Original />} />
            </Routes>
          );
    };
}