import {
  Box,
  Container,
  Stack,
  Text,
  Link,
  useColorModeValue,
} from '@chakra-ui/react';

export default function Footer() {
  return (
    <Box
    borderTopWidth={1}
    borderStyle={'solid'}
    borderColor={useColorModeValue('gray.200', 'gray.700')}
    marginTop= '30px'>
    <Container
      as={Stack}
      maxW={'6xl'}
      py={4}
      direction={{ base: 'column', md: 'row' }}
      spacing={4}
      justify={{ md: 'space-between' }}
      align={{ md: 'center' }}>
      <Text style={{width: '82%', margin: 'auto'}}>© 2024 <Link href={'http://www.yea.dev'} target="_blank">Yea.dev</Link> All rights reserved</Text>
    </Container>
  </Box>
  );
}