import { Dispatch, SetStateAction } from "react";
import { useNumberInput, Stack, Button, Input } from "@chakra-ui/react";
import { toaster } from "evergreen-ui";

interface INumberIncreaserProps {
    setLoremCount: Dispatch<SetStateAction<number>>;
}

function NumberIncreaser({ setLoremCount }: INumberIncreaserProps) {
    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
      useNumberInput({
        step: 1,
        defaultValue: 2,
        min: 1,
        max: 500,
        precision: 0,
      })
  
    const inc = getIncrementButtonProps()
    const dec = getDecrementButtonProps()
    const input = getInputProps()

    const generateClickHandler = () => {
      setLoremCount(input.value)
      toaster.success(`Generated ${input.value} ${input.value > 1 ? 'paragraphs' : 'paragraph'}`);
    }

    return (
        <div style={{textAlign: 'center'}}>
        <Stack direction={'row'}>
            <Button colorScheme='red' {...dec}>-</Button>
            <Input color={'black'} {...input} />
            <Button colorScheme='green' {...inc}>+</Button>
        </Stack>
        <Button colorScheme='blue' onClick={generateClickHandler} style={{marginTop: '10px'}}>Generate Paragraphs</Button>
        </div>
    )
}

export default NumberIncreaser;