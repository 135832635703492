import { Heading, Text, Image } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet';

import { DEFAULT_META_KEYWORDS, DEFAULT_META_DESCRIPTION, Wrapper, ContentWrapper } from './Home';

export default function Original() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  var size = 'sm';
  if (isMobile) { size = 'xs'} else { size ='sm'}
  return (
      <>
      <Helmet>
        <title>Original Passage - Lorem Ipsum Generator</title>
        <meta name="description" content={DEFAULT_META_DESCRIPTION} />
        <meta name="keywords" content={DEFAULT_META_KEYWORDS} />
      </Helmet>
      <ContentWrapper>
        <Wrapper>
        <Heading color="black" size='4xl'>Lorem Ipsum</Heading>
        {isMobile ? <Image src={require('../Images/cicero.jpg')} alt='Cicero Lorem Ipsum' style={{margin: 'auto', paddingTop: '20px'}} height={'70%'} width={'70%'} /> : <Image src={require('../Images/cicero.jpg')} alt='Cicero Lorem Ipsum' style={{margin: 'auto', paddingTop: '20px'}} height={'40%'} width={'40%'} /> }
        <Text fontSize='md' color="black" style={{whiteSpace: "pre-line", paddingTop: '30px'}}>
          Lorem ipsum is a commonly used placeholder text in the design and publishing industries. It has been used for centuries as a dummy text for printing and typesetting. The origins of Lorem ipsum can be traced back to the classical Latin literature of the 1st century BC, where it was used as a filler text by Roman philosopher and statesman Cicero.{'\n\n'}
          The Lorem ipsum text gained popularity in the 20th century with the rise of the printing and publishing industry. It was popularized by the Letraset company, which used Lorem ipsum as a sample text in their sheets of transferable typefaces. Later, the Lorem ipsum text was included in desktop publishing software such as Aldus PageMaker, and it quickly became the standard dummy text for designers and publishers.{'\n\n'}
          Today, Lorem ipsum remains a popular placeholder text and has been used in countless designs and layouts. Despite its widespread use, the meaning of Lorem ipsum remains largely unknown to most people, as it is a jumbled mix of Latin words and phrases without any real meaning.
        </Text>
        <Wrapper>
        </Wrapper>
        <Heading color="black" as='h2' size='xl'>Original Passage</Heading>
        <Text fontSize='md' color="black" style={{whiteSpace: "pre-line", paddingTop: '30px'}}>
          The original Lorem Ipsum passage comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. The passage reads as follows:{'\n\n'}
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.{'\n\n'}
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."{'\n\n'}
          The passage has been modified and adapted over time for use as a placeholder text in various industries.
        </Text>
        </Wrapper>
      </ContentWrapper>
      </>
    );
}