import { useEffect, useState }from "react";
import { Text, Button, Textarea, Heading } from '@chakra-ui/react'
import { loremIpsum } from 'react-lorem-ipsum';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { toaster } from "evergreen-ui";

import NumberIncreaser from "./NumberIncreaser";

export const Lorem = () => {
    const [loremCount, setLoremCount] = useState(2);
    const [loremText, setLoremText] = useState("");
    const [oldLoremCount, setOldLoremCount] = useState(1);
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    const copyHandler = () => {
        navigator.clipboard.writeText(loremText);
        toaster.success('Copied to clipboard');
    }

    useEffect(() => {
        if (loremCount === oldLoremCount) { return; }
        setOldLoremCount(loremCount);
        var completedText = "";
        loremIpsum({ p: loremCount, random: true }).map(text => {
            if (completedText) { completedText = completedText+'\n\n'+text; } else { completedText = text; }
            return completedText;
        })
        setLoremText(completedText)
        
    }, [loremCount, loremText, oldLoremCount]);
    
    return (
        <Text fontSize='md' color="black">
            <Wrapper>
                <Heading size={'sm'} style={{paddingBottom: 10}} color="black">How Many Paragraphs Should be Generated?</Heading>
                {isMobile ? <div style={{paddingTop: '20px'}}/> : null }
                <NumberIncreaser setLoremCount={setLoremCount}/>
            </Wrapper>
            <CopyBtnWrapper>
                <Button onClick={copyHandler}>Copy</Button>
            </CopyBtnWrapper>
            <Textarea
                value={loremText}
                onChange={(e) => setLoremText(e.target.value)}
                placeholder="Why'd you delete everything??"
                size='lg'
                variant={"unstyled"}
                style={{height: loremCount > 50 ? loremCount * 2 : 500}}
            />
        </Text>
    );
}

const Wrapper = styled.div`
  width: 80%;
  padding-bottom: 40px;
  margin: 0 auto;
`;

const CopyBtnWrapper = styled.div`
margin-bottom: 20px;
`