import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
  } from '@chakra-ui/react';
  import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
    ChevronRightIcon,
  } from '@chakra-ui/icons';

  export const DesktopNav = () => {
    const linkColor = useColorModeValue('black', 'white');
    const linkHoverColor = useColorModeValue('gray.400', 'white');
    const popoverContentBgColor = useColorModeValue('black', 'white');
  
    return (
        <>
        <Stack direction={'row'} spacing={4} style={{
            padding: '15px',
            margin: 'auto',
            width: '60%',
            textAlign: 'center',
            }}>
            {NAV_ITEMS.map((navItem) => (
            <Box key={navItem.label}>
                <Popover trigger={'hover'} placement={'bottom-start'}>
                <PopoverTrigger>
                    <Link
                    p={3}
                    href={navItem.href ?? '#'}
                    fontSize={'sm'}
                    fontWeight={500}
                    color={linkColor}
                    style={{textAlign: 'center'}}
                    _hover={{
                        textDecoration: 'none',
                        color: linkHoverColor,
                    }}>
                    {navItem.label}
                    </Link>
                </PopoverTrigger>
                </Popover>
            </Box>
            ))}
        </Stack>
        <hr />
        </>
    );
  };

  interface NavItem {
    label: string;
    subLabel?: string;
    children?: Array<NavItem>;
    href?: string;
  }
  
  const NAV_ITEMS: Array<NavItem> = [
    {
      label: 'Home',
      href: '/',
    },
    {
      label: 'Lorem Ipsum Generator',
      href: '/generator',
    },
    {
      label: 'Original Passage',
      href: '/original-passage',
    },
  ];