import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react";
import '@fontsource/raleway/400.css'

import Main from './Main';
import { DesktopNav } from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import theme from './theme'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <ChakraProvider theme={theme}>
      <DesktopNav />
      <Analytics />
      <SpeedInsights />
      <Main />
      <Footer />
    </ChakraProvider>
  </BrowserRouter>
);
