import { useEffect, useState } from 'react';
import { Heading, Divider, Stack, Text, Image, Button, Link,  } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet';
import { loremIpsum } from 'react-lorem-ipsum';

export const DEFAULT_META_DESCRIPTION = "Generate random, filler text for your designs with our Lorem Ipsum generator. Get instant access to endless paragraphs of placeholder text to help you focus on design without worrying about content. Try it now for free!"
export const DEFAULT_META_KEYWORDS = "lorem ipsum, lorem lipsum, lipsum lorem, lorem ipsum generator, lorem generator, lipsum generator, ipsum generator"

export default function Home() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  var size = 'sm'
  if (isMobile) { size = 'xs'} else { size ='sm'}

  const [loremCount, setLoremCount] = useState(2);
  const [loremText, setLoremText] = useState("");
  const [oldLoremCount, setOldLoremCount] = useState(1);

  useEffect(() => {
      if (loremCount === oldLoremCount) { return; }
      setOldLoremCount(loremCount);
      var completedText = "";
      loremIpsum({ p: loremCount, random: true }).map(text => {
          if (completedText) { completedText = completedText+'\n\n'+text; } else { completedText = text; }
          return completedText;
      })
      setLoremText(completedText)
      
  }, [loremCount, loremText, oldLoremCount]);


  return (
    <>
    <Helmet>
        <title>Home - Lorem Ipsum Generator</title>
        <meta name="description" content={DEFAULT_META_DESCRIPTION} />
        <meta name="keywords" content={DEFAULT_META_KEYWORDS} />
    </Helmet>
    <ContentWrapper>
      <Wrapper>
      <Heading color="black" size='4xl'>Lorem Ipsum</Heading>
        <Stack direction='row' h='100px' p={4}>
          <Divider orientation='vertical' />
            <Heading as='h5' size={size} color="gray" style={{whiteSpace: "pre-line"}}>
              One must still have chaos in oneself to be able to give birth to a dancing star.
              {'\n\n'}- Friedrich Nietzsche
            </Heading>
        </Stack>
      </Wrapper>
      <CustomWrapper>
        <Text style={{marginBottom: '40px'}}>{loremText}</Text>
        <Link href='/generator'>
          <Button style={{padding: '20px', textAlign: 'center'}} rightIcon={<ArrowForwardIcon />} height={10} width={200} backgroundColor={'blue.400'} color={'white'}>Go To Generator</Button>
        </Link>
      </CustomWrapper>
      {/*}
      <Wrapper>
      {isMobile ? <div style={{paddingTop: '20px'}}/> : null }
      </Wrapper>
      */}
      <Wrapper>
        {isMobile ? <div style={{paddingTop: '20px'}}/> : null }
        <Heading color="black" as='h2' size='xl' style={{paddingBottom: '20px'}}>
          What is Lorem Ipsum?
        </Heading>
        {isMobile ? <Image src={require('../Images/portrait.jpg')} alt='Cicero Lorem Ipsum' style={{float: 'left', paddingRight: '20px', paddingBottom: '20px'}} height={'70%'} width={'70%'} /> : <Image src={require('../Images/portrait.jpg')} alt='Cicero Lorem Ipsum' style={{float: 'left', paddingRight: '20px', paddingBottom: '20px'}} height={'40%'} width={'40%'} /> }
        <Text fontSize='md' color="black" style={{whiteSpace: "pre-line"}}>
        Lorem Ipsum is a placeholder text that designers and developers use to fill the space of a website or document with dummy content when the actual content is not yet available. The text has been used since the 16th century and is derived from a Latin text by Cicero. The Lorem Ipsum passage has been the industry standard for dummy text for centuries, and it's still widely used today.{"\n\n"}
        The Lorem Ipsum text consists of a scrambled version of Latin words and phrases, making it look like real text but without any actual meaning. The original text was found in a book called "De Finibus Bonorum et Malorum" (The Extremes of Good and Evil) written by Cicero in 45 BC. However, the text was not used as a placeholder until the 16th century, when a printer named Aldus Manutius used it in his typesetting work. Since then, the Lorem Ipsum passage has been the go-to dummy text for designers and developers.{"\n\n"}
        The use of Lorem Ipsum has several benefits. Firstly, it allows designers and developers to focus on the design and layout of a website or document without worrying about the actual content. Secondly, it helps to create a realistic-looking layout that can be presented to clients or stakeholders. Finally, using Lorem Ipsum can also save time, as it's quicker to copy and paste the text than to come up with original content.{"\n\n"}
        Despite being over 2000 years old, the Lorem Ipsum text is still widely used today in the design and development industry. It has become an iconic element of design, and its use is likely to continue for many years to come.
        </Text>
      </Wrapper>
      <Wrapper>
      <Heading color="black" as='h2' size='xl' style={{paddingBottom: '20px'}}>
          Why is Lorem Ipsum Important?
        </Heading>
        <Text fontSize='md' color="black" style={{whiteSpace: "pre-line"}}>
        1. It allows designers and developers to focus on the design and layout of a website or document without worrying about the actual content. This is particularly useful in the early stages of a project, where the content may not yet be available or finalized.{"\n\n"}
        2. It helps to create a realistic-looking layout that can be presented to clients or stakeholders. Using Lorem Ipsum text gives a sense of how the final product will look once the content is added, making it easier for clients to understand the overall design and layout.{"\n\n"}
        3. It can save time, as it's quicker to copy and paste the text than to come up with original content. This is especially true for projects with a large amount of content, where creating original content can be time-consuming and costly.{"\n\n"}
        4. It has become an iconic element of design, and its use is likely to continue for many years to come. The use of Lorem Ipsum has become a standard practice in the design and development industry, making it an important part of the design process.{"\n\n"}
        </Text>
      </Wrapper>
      <Wrapper>
      <Heading color="black" as='h2' size='xl' style={{paddingBottom: '20px'}}>
          Is It Free to Use?
        </Heading>
        <Text fontSize='md' color="black" style={{whiteSpace: "pre-line"}}>
        Yes, Lorem Ipsum text is free to use. Since the text has no real meaning, there are no copyright or licensing issues associated with using it. The Lorem Ipsum text is often included in text editors, design software, and content management systems, making it easily accessible for anyone who needs to use it. However, it's worth noting that while the text itself is free to use, the design or layout that incorporates the text may be subject to copyright or other legal restrictions. So, it's always important to ensure that any design work created using Lorem Ipsum text does not infringe on any copyright or intellectual property rights.
        </Text>
      </Wrapper>
    </ContentWrapper>
    </>
  );
}

export const Wrapper = styled.div`
  width: 62%;
  padding: 40px;
  margin: 0 auto;
  background-color: white;

  @media (max-width: 768px) {
    width: 92%;
    text-align: center;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const CustomWrapper = styled.div`
  width: 60%;
  margin 0 auto;
  margin-top: 20px;
  margin-bottom: 30px;
  background-color: white;
  border-radius: 20px;
  text-align: center;

  @media (max-width: 768px) {
    width: 82%;
  }
`